<template>
  <div>
    <!-- <vx-card actionable class="cardx" :title="`Master Performance CPA/CMA - ${generated_date}`"> -->
    <vx-card actionable class="cardx">
      <vs-row class="ml-4 mb-2">
        <vs-col vs-justify="center" vs-align="flex-start" vs-w="6">
          <h4>Master Performance {{ selected_course }} - {{ generated_date }}</h4>
        </vs-col>
      </vs-row>
      <div style="display: flex;" class="mb-3 pl-4">
        <v-select
          class="ml-4"
          v-model="selected_month"
          :options="months"
          style="width: 400px; margin-right:15px; z-index: 1000"
          placeholder="Months"
        />
        <vs-button
          color="dark"
          type="filled"
          @click="refreshData()"
          icon="refresh"
          style="margin-right: 3px"
        >
        </vs-button>
        <vs-button
          v-if="table_data.length > 1 && (logged_in_spoc_id == 132 || logged_in_spoc_id == 122 || logged_in_spoc_id == 937)"
          color="primary"
          @click="downloadCvsTable"
          style="margin-right: 3px"
        >
          Download
        </vs-button>
      </div>
      <vs-row type="flex" v-if="table_show" class="ml-4 mb-3">
        <!--Team -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <treeselect
            v-model="selected_team"
            :multiple="true"
            :options="teams"
            placeholder="Teams"
          />
        </vs-col>
        <!--GM -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" >
          <treeselect
            v-model="selected_GM"
            :multiple="true"
            :options="gmData"
            placeholder="GM"
          />
        </vs-col>
        <!--City Classification -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" >
          <treeselect
            v-model="initSelectedCC"
            :multiple="true"
            :options="treeDataCC"
            placeholder="City Classification"
          />
        </vs-col>
        <!--SPOC LEVLES -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5" >
          <treeselect
            v-model="selected_level"
            :multiple="true"
            :options="spocLevels"
            placeholder="SPOC Levels"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="1.5" >
          <vs-button color="dark" type="filled" @click="getPerformanceData">GO</vs-button>
        </vs-col>
      </vs-row>  
      <div v-if="table_show" style="margin-left: 27px">
        <div ref="table1"></div>
      </div>
      <div v-if="show_no_data" style="color: grey; margin: 5px" class="mt-3">
        <center>NO DATA AVAILABLE</center>
      </div>
      <vs-popup class="holamundo"  title="Delete Comment" :active.sync="open_delete_popup">
        <vs-table :data="delete_comments">
          <template slot="thead">
            <vs-th>
              <b>Comments</b>
            </vs-th>
            <vs-th>
              <b>Action</b>
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr>
              <vs-td>
                <b>Delete All Comments</b>
              </vs-td>
              <vs-td>
                <vs-button
                  @click="deleteComment('delete all comments',delete_comment_object.user_id)"
                  color="primary"
                  icon="delete"
                  size="small"
                  >
                  </vs-button>
              </vs-td>
            </vs-tr>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td  :data="data[indextr].comment_with_time">
                {{data[indextr].comment_with_time}}
              </vs-td>
              <vs-td  :data="data[indextr].comment_with_time">
                <vs-button
                  @click="deleteComment('delete single comment',tr.id)"
                  color="primary"
                  icon="delete"
                  size="small"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <vs-popup class="holamundo"  title="Add Comment" :active.sync="open_add_comment_popup">
        <vs-row>
          <vs-col vs-w='12'>
            <vs-textarea v-model="new_comment" />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-align="flex-end"  vs-justify="flex-end" vs-w='12'>
            <vs-button color="dark" @click="addMasterPerformanceComments">Add</vs-button> 
          </vs-col>
        </vs-row>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import constants from "./../../../constants.json";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
    Treeselect
  },
  data() {
    return {
      teams: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      selected_GM:[],
      gm_data:[],
      gmData:[
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      selected_level:[],
      level_data:[],
      team_data:[],
      cc_data:[],
      spocLevels: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCC: [],
      treeDataCC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      table_name:"",
      performance_data:[],
      selected_team:[],
      selected_city:"",
      cities:[],
      open_add_comment_popup: false,
      new_comment: "",
      delete_comments: [],
      delete_comment_object: {
        user_id: null,
        comment: ""
      },
      open_delete_popup: false,
      cell: "",
      data: "",
      comments_data: [],
      logged_in_spoc_id: null,
      selected_course: "CPA/CMA",
      months_tobe_with_year: [],
      gm_list: [],
      generated_date: "",
      city_classification_list: [],
      selected_city_classification: [],
      table_show: false,
      show_no_data: true,
      table_data: [],
      selected_month: "",
      year_table_headers: [],
      year_table_headers_first: [],
      year_table_headers_last: [
        { title: "CPA", field: "CPA", hozAlign: "center", bottomCalc: "sum" },
        { title: "CMA", field: "CMA", hozAlign: "center", bottomCalc: "sum" },
        { title: "CPA-AA", field: "CPA_AA", hozAlign: "center", bottomCalc: "sum" },
        { title: "CFA", field: "CFA", hozAlign: "center", bottomCalc: "sum" },
        { title: "FRM", field: "FRM", hozAlign: "center", bottomCalc: "sum" },
        { title: "USP", field: "USP", hozAlign: "center", bottomCalc: "sum" },
        {
          title: "AICPA-Miles",
          field: "AICPA_Miles",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "AICPA-Non-Miles",
          field: "AICPA_Non_Miles",
          hozAlign: "center",
          bottomCalc: "sum",
        },
            
        {
          title: "Total Calls",
          field: "total_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Calls/Day",
          field: "calls_per_day",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Total Unique Connected Calls",
          field: "total_unique_connected_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Connected Calls",
          field: "total_connected_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Utilization",
          field: "utilization",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        { title: "Mandays", field: "mandays", hozAlign: "center", bottomCalc: "sum" },
        { title: "Q1 Sales", field: "Q1_sales", hozAlign: "center", bottomCalc: "sum" },
        { title: "Q2 Sales", field: "Q2_sales", hozAlign: "center", bottomCalc: "sum" },
        { title: "Q3 Sales", field: "Q3_sales", hozAlign: "center", bottomCalc: "sum" },
        { title: "Q4 Sales", field: "Q4_sales", hozAlign: "center", bottomCalc: "sum" },
        {
          title: "Webinar Count(R | A)",
          field: "webinar_count",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Visits(T | U | E)",
          field: "visit_count",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        // { title: "Employee Code", field: "e_code", hozAlign: "center"},
        // { title: "Email", field: "email", hozAlign: "center" },
      ],
      hf_nhf: [],
      months: [],
      year_headers: [],
      year_month: [],
      year_month_data: [],
      all_details:[],
    };
  },
  mounted() {
    this.logged_in_spoc_id = localStorage.getItem("spoc_id");
    if(this.logged_in_spoc_id == 132 || this.logged_in_spoc_id == 122 || this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
      let old_comment = {title:"Comments", field:"old_comment", width:250, editor:"textarea"}
      this.year_table_headers_last.push(old_comment);
      // let new_comment = {title:"Add Comment", field:"new_comment", width:150, editor:"textarea"}
      // this.year_table_headers_last.push(new_comment);
      let save_comment = {title:"Add Comment", width:150, formatter: "buttonTick", hozAlign: "center"};
      this.year_table_headers_last.push(save_comment);
      let delete_comment = {title:"Delete Comment", formatter: "buttonCross", width:150, hozAlign:"center"};
      this.year_table_headers_last.push(delete_comment);
    }
    let employee_code = { title: "Employee Code", field: "e_code", hozAlign: "center"};
    this.year_table_headers_last.push(employee_code);
    let email = { title: "Email", field: "email", hozAlign: "center" };
    this.year_table_headers_last.push(email);
    this.getMasterPerformanceComments();
    this.getMonth();
  },
  watch: {
    table_show(val) {
      console.log("table_show", val);
    },
    show_no_data(val) {
      console.log("show_no_data", val);
      // if(val == true){
      //   this.table_data = false;
      // }
    },
    selected_level: function () {
      this.mergeSelector();
    },
    initSelectedCC: function () {
      this.mergeSelector();
    },
    selected_team: function () {
      this.mergeSelector();
    },
    selected_GM:function(){
      this.mergeSelector();
    },
    selected_month(val) {
      console.log("selected_monthval", val);
      if (val != "" && val != null) {
        this.table_show = true;
        console.log(this.table_show, this.show_no_data, "selected_month");
        this.search_month = val;
        let year = moment().startOf("year").format("YYYY");
        let previous_year = year - 1;
        if (this.search_month == `YTD-${year}`) {
          this.table_name = "year_table"
          this.getData(val, "year_table");
          // this.currentYearTable()
        } else if (this.search_month == `YTD-${previous_year}`) {
          this.getData(val, "previous_year_table_headers");
          this.table_name = "previous_year_table_headers"
        } else {
          this.getData(val, "month_table");
          this.table_name = "month_table";
        }
      }
      if (val == null) {
        this.table_show = false;
        this.show_no_data = true;
      }
    },
  },
  methods: {
    mergeSelector(){
      this.level_data = [];
      this.selected_level.forEach((sort) => {
        if (sort === "All") {
          this.spocLevels[0].children.forEach((child) => {
            this.level_data.push(child.label);
          });
        } else {
          this.level_data.push(sort);
        }
      });
      this.team_data =[];
      this.selected_team.forEach((sort) => {
        if (sort === "All") {
          this.teams[0].children.forEach((child) => {
            this.team_data.push(child.label);
          });
        } else {
          this.team_data.push(sort);
        }
      });
      this.cc_data = [];
      this.initSelectedCC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCC[0].children.forEach((child) => {
            this.cc_data.push(child.label);
          });
        } else {
          this.cc_data.push(sort);
        }
      });
      this.gm_data = [];
      this.selected_GM.forEach((sort) => {
        if (sort === "All") {
          this.gmData[0].children.forEach((child) => {
            this.gm_data.push(child.label);
          });
        } else {
          this.gm_data.push(sort);
        }
      });
    },
    getPerformanceData(){
      this.all_details = [];
        for (let i = 0; i < this.performance_data.length; i++) {
          const element = this.performance_data[i];
          if (this.team_data.length > 0 && this.cc_data.length == 0 && this.selected_level.length == 0 && this.gm_data.length == 0) {
              this.team_data.forEach((team) => {
              if (element.team == team) {
                this.all_details.push(element);
              }
            });
          }else if(this.team_data.length == 0 && this.cc_data.length > 0 && this.level_data.length == 0 && this.gm_data.length == 0) {
              this.cc_data.forEach((cc) => {
              if (element.city_classification == cc) {
                this.all_details.push(element);
              }
            });
          }
          else if(this.team_data.length == 0 && this.cc_data.length == 0 && this.level_data.length > 0 && this.gm_data.length == 0) {
            this.level_data.forEach((level) => {
              if (element.user_tag == level) {
                this.all_details.push(element);
              }
            });
          }
          else if(this.team_data.length == 0 && this.cc_data.length == 0 && this.level_data.length == 0 && this.gm_data.length > 0) {
            this.gm_data.forEach((gm) => {
              if (element.gm + "-" + element.team + "-"+ element.city_classification == gm) {
                this.all_details.push(element);
              }
            });
          }
          else if(this.team_data.length > 0 && this.cc_data.length > 0 && this.level_data.length == 0 && this.gm_data.length == 0) {
           this.team_data.forEach((team) => {
            this.cc_data.forEach((cc) => {
              if(element.team == team && element.city_classification == cc){
                 this.all_details.push(element);
              }
            })
           });
          }
          else if(this.team_data.length == 0 && this.cc_data.length > 0 && this.level_data.length > 0 && this.gm_data.length == 0) {
            this.cc_data.forEach((cc) => {
              this.level_data.forEach((level) => {
                if(element.user_tag == level && element.city_classification == cc){
                  this.all_details.push(element);
                }
              })
           });
          }
          else if(this.team_data.length == 0 && this.cc_data.length == 0 && this.level_data.length > 0 && this.gm_data.length > 0) {
           this.gm_data.forEach((gm) => {
            this.level_data.forEach((level) => {
              if(element.gm + "-" + element.team + "-"+ element.city_classification  == gm && element.user_tag == level){
                 this.all_details.push(element);
              }
            })
           });
          }
          else if(this.team_data.length > 0 && this.cc_data.length == 0 && this.level_data.length == 0 && this.gm_data.length > 0) {
           this.team_data.forEach((team) => {
            this.gm_data.forEach((gm) => {
              if(element.team == team && element.gm + "-" + element.team + "-"+ element.city_classification == gm){
                 this.all_details.push(element);
              }
            })
           });
          }
          else if(this.team_data.length == 0 && this.cc_data.length > 0 && this.level_data.length == 0 && this.gm_data.length > 0) { 
          this.gm_data.forEach((gm) => {
            this.cc_data.forEach((cc) => {
              if(element.city_classification == cc && element.gm + "-" + element.team + "-"+ element.city_classification == gm){
                 this.all_details.push(element);
              }
            })
           });
          }
          else if(this.team_data.length > 0 && this.cc_data.length > 0 && this.level_data.length > 0 && this.gm_data.length == 0) {
            this.team_data.forEach((team) => {
              this.cc_data.forEach((cc)=>{
                  this.level_data.forEach((level) => {
                  if(element.team == team && element.user_tag == level && element.city_classification == cc){
                  this.all_details.push(element);
                  }
                });
              });
           });
          }
          else if(this.team_data.length == 0 && this.cc_data.length > 0 && this.level_data.length > 0 && this.gm_data.length > 0) {
            this.gm_data.forEach((gm) => {
              this.cc_data.forEach((cc)=>{
                this.level_data.forEach((level) => {
                  if(element.gm + "-" + element.team + "-"+ element.city_classification == gm && element.user_tag == level && element.city_classification == cc){
                  this.all_details.push(element);
                  }
                });
              });
           });
          }
          else if(this.team_data.length > 0 && this.cc_data.length == 0 && this.level_data.length > 0 && this.gm_data.length > 0) {
              this.team_data.forEach((team)=>{
                this.gm_data.forEach((gm) => {
                  this.level_data.forEach((level) => {
                  if(element.gm + "-" + element.team + "-"+ element.city_classification == gm && element.user_tag == level && element.team == team){
                  this.all_details.push(element);
                  }
                });
              });
           });
          }
          else if(this.team_data.length > 0 && this.cc_data.length > 0 && this.level_data.length == 0 && this.gm_data.length > 0) {
              this.team_data.forEach((team)=>{
                this.gm_data.forEach((gm) => {
                  this.cc_data.forEach((cc) => {
                  if(element.team == team && element.gm + "-" + element.team + "-"+ element.city_classification == gm && element.city_classification == cc){
                  this.all_details.push(element);
                  }
                });
              });
           });
          }
          else if(this.team_data.length > 0 && this.cc_data.length > 0 && this.level_data.length > 0 && this.gm_data.length > 0) {
              this.team_data.forEach((team)=>{
                this.gm_data.forEach((gm) => {
                  this.cc_data.forEach((cc) => {
                    this.level_data.forEach((level) => {
                      if(element.gm + "-" + element.team + "-"+ element.city_classification == gm && element.team == team && element.city_classification == cc && element.user_tag == level){
                      this.all_details.push(element);
                      }
                  });
                });
              });
           });
          }
      }
      this.getData(this.selected_month,this.table_name);
    },
    getTeam(data){
      this.teams[0].children = [];
      this.spocLevels[0].children = [];
      this.treeDataCC[0].children = [];
      this.gmData[0].children = [];
      const team_value = [
            ...new Set(data.map((item) => item.team)),
          ];
      for (let i = 0; i < team_value.length; i++) {
        const element = team_value[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.teams[0].children.push(obj);
        }
      }
      const gm_value = [
            ...new Set(data.map((item) => item.gm + "-" + item.team + "-"+ item.city_classification)),
          ].sort();
      for (let i = 0; i < gm_value.length; i++) {
        const element = gm_value[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.gmData[0].children.push(obj);
        }
      }
      const spoc_value = [
            ...new Set(data.map((item) => item.user_tag)),
          ];
      for (let i = 0; i < spoc_value.length; i++) {
        const element = spoc_value[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.spocLevels[0].children.push(obj);
        }
      }
      const value = [...new Set(data.map((item) => item.city_classification))].sort();
      for (let index = 0; index < value.length; index++) {
        const data = value[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      } 
    },
    getMonth() {
      let year = moment().startOf("year").format("YYYY");
      let list_of_months = moment.months(year);
      let current_month = moment().get("month") + 1;
      list_of_months.length = current_month;
      this.months = list_of_months;
      this.months_tobe_with_year = this.months;
      // this.months_tobe_with_year = this.months;
      let previous_year = year - 1;
      this.months.unshift("YTD" + "-" + previous_year);
      this.months.unshift("YTD" + "-" + year);
    },
    // currentYearTable() {
    //   let year = moment().startOf("year").format("YYYY");
    //   let list_of_months = moment.months(year);
    //   let current_month = moment().get("month") + 1;
    //   list_of_months.length = current_month;
    //   this.year_month = list_of_months.reverse();
    //   console.log("list_of_months", this.year_month)
    //   for (let i = 0; i < this.year_month.length; i++) {
    //     const element = this.year_month[i];
    //     // console.log("element", element)
    //     let year_field_name = {
    //       "December": "dec",
    //       "November": "nov",
    //       "October": "oct",
    //       "September": "sep",
    //       "August": "aug",
    //       "July": "jul",
    //       "June": "jun",
    //       "May": "may",
    //       "April": "apr",
    //       "March": "mar",
    //       "February": "feb",
    //       "January": "jan"
    //     }
    //     let month_data = { title: element, field: year_field_name[element], hozAlign: "center", bottomCalc: "sum" }
    //     this.year_month_data.push(month_data)
    //   }
    //   this.year_table_headers = this.year_table_headers_first.concat(this.year_month_data, this.year_table_headers_last)
    // },
    getData(month, tableHeader) {
      this.getMasterPerformanceComments();
      console.log("tableHeader", tableHeader);
      this.$vs.loading();
      let year = moment().startOf("year").format("YYYY");
      let previous_year = year - 1;
      let file_name = "";
      if (this.months_tobe_with_year.includes(month)) {
        file_name = month + "_" + year;
      }
      if (month == `YTD-${year}`) {
        file_name = `YTD-${year}`;
      }
      if (month == `YTD-${previous_year}`) {
        file_name = `YTD-${previous_year}`;
      }
      const url = `${constants.MILES_CM_BACK}getMasterPerformanceData?file_name=${file_name}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          let data = response.data.data;
          this.performance_data = data;
          this.data = response.data.data;
          this.getTeam(data);
          data.forEach(element => {
            // let new_dayas_from_join = element.days_from_join;
            // element.days_from_join = new_dayas_from_join[0] + " " + new_dayas_from_join[1]
            this.comments_data.forEach(comment => {
              if(element.user_id == comment.user_id){
                if(element.hasOwnProperty('old_comment')){
                  let new_comment = moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment;
                  let new_string =  new_comment + "\n" + element.old_comment;
                  Object.assign(element, {old_comment: new_string})
                }  
                if(!element.hasOwnProperty('old_comment')){
                  console.log("date",moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment);
                  let new_comment = moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment;
                  Object.assign(element, {old_comment: new_comment})
                }
                console.log("show");
              }  
            });
          });
          this.city_classification_list = [
            ...new Set(
              data.map((item) => {
                if (item.city_classification != null) return item.city_classification;
              })
            ),
          ];
          let city = this.city_classification_list.sort();
          this.gm_list = [...new Set(data.map((item) => item.gm))];
          let gm_sort = this.gm_list.sort();
          this.hf_nhf = [...new Set(data.map((item) => item.user_tag))];
          let hf_nhf_sort = this.hf_nhf.sort();
          // console.log("city", city, this.city_classification_list);

          let month_table_headers = [
            {
              title: "Name",
              field: "name",
              hozAlign: "center",
              frozen: true,
              sorter: "alphanum",
            },
            {
              title: "Date of Joining",
              field: "date_of_joining",
              hozAlign: "center",
              headerSort: false,
              frozen: true,
            },
            {
              title: "Tenure ",
              field: "days_from_join",
              hozAlign: "center",
              sorter: "alphanum",
              bottomCalc: "sum",
              frozen: true,
            },
            {
              title: "City",
              field: "city_classification",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: city },
            },
            {
              title: "GM",
              field: "gm",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: gm_sort },
            },
            {
              title: "SPOC Level",
              field: "user_tag",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: hf_nhf_sort },
            },
            {
              title: "Total Sales",
              field: "total_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            { title: "CPA", field: "CPA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CMA", field: "CMA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CPA-AA", field: "CPA_AA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CFA", field: "CFA", hozAlign: "center", bottomCalc: "sum" },
            { title: "FRM", field: "FRM", hozAlign: "center", bottomCalc: "sum" },
            { title: "USP", field: "USP", hozAlign: "center", bottomCalc: "sum" },
            {
              title: "AICPA-Miles",
              field: "AICPA_Miles",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "AICPA-Non-Miles",
              field: "AICPA_Non_Miles",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Total Calls",
              field: "total_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Calls/Day",
              field: "calls_per_day",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Total Unique Connected Calls",
              field: "total_unique_connected_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Connected Calls",
              field: "total_connected_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Utilization",
              field: "utilization",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            { title: "Mandays", field: "mandays", hozAlign: "center", bottomCalc: "sum" },
            {
              title: "YTD Sales",
              field: "ytd_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q1 Sales",
              field: "Q1_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q2 Sales",
              field: "Q2_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q3 Sales",
              field: "Q3_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q4 Sales",
              field: "Q4_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Webinar Count(R | A)",
              field: "webinar_count",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Visits(T | U | E)",
              field: "visit_count",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            // { title: "Email", field: "email", hozAlign: "center" },
            // { title: "Employee Code", field: "e_code", hozAlign: "center",},
          ];
          if(this.logged_in_spoc_id == 132 || this.logged_in_spoc_id == 122 || this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
            // month_table_headers.push();  
            let old_comment = {title:"Comments", field:"old_comment", width:250, editor:"textarea"}
            month_table_headers.push(old_comment);
            // let new_comment = {title:"Add Comment", field:"new_comment", width:150, editor:"textarea"}
            // month_table_headers.push(new_comment);
            let save_comment = {title:"Add Comment", width:150, formatter: "buttonTick", hozAlign: "center",};
            month_table_headers.push(save_comment);

            let delete_comment = {title:"Delete Comment", formatter: "buttonCross", width:150, hozAlign:"center"};
            month_table_headers.push(delete_comment);
          }
          let employee_code = { title: "Employee Code", field: "e_code", hozAlign: "center",};
          month_table_headers.push(employee_code);
          let email = { title: "Email", field: "email", hozAlign: "center" };
          month_table_headers.push(email);
          let year_table_headers_first = [
           
            {
              title: "Name",
              field: "name",
              hozAlign: "center",
              frozen: true,
              headerHozAlign: "center",
              sorter: "alphanum",
            },
            {
              title: "Date of Joining",
              field: "date_of_joining",
              hozAlign: "center",
              headerSort: false,
              frozen: true,
            },
            {
              title: "Tenure ",
              field: "days_from_join",
              hozAlign: "center",
              sorter: "alphanum",
              bottomCalc: "sum",
              frozen: true,
            },
            {
              title: "City",
              field: "city_classification",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: city },
            },
            {
              title: "GM",
              field: "gm",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: gm_sort },
            },
            {
              title: "SPOC Level",
              field: "user_tag",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: hf_nhf_sort },
            },
            { title: "YTD", field: "ytd_sales", hozAlign: "center" },
            
          ];
          let previous_year_table_headers = [
            
            {
              title: "Name",
              field: "name",
              hozAlign: "center",
              frozen: true,
              headerHozAlign: "center",
              sorter: "alphanum",
            },
            { title: "Date of Joining", field: "date_of_joining", headerSort: false, frozen: true,},
            {
              title: "Tenure ",
              field: "days_from_join",
              hozAlign: "center",
              sorter: "alphanum",
              bottomCalc: "sum",
              frozen: true,
            },
            {
              title: "City",
              field: "city_classification",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: city },
            },
            {
              title: "Gm",
              field: "gm",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: gm_sort },
            },
            {
              title: "SPOC Level",
              field: "user_tag",
              hozAlign: "center",
              headerFilter: "list",
              headerFilterParams: { values: hf_nhf_sort },
            },
            { title: "YTD", field: "ytd_sales", hozAlign: "center", bottomCalc: "sum" },
            
            { title: "December", field: "dec", hozAlign: "center", bottomCalc: "sum" },
            { title: "November", field: "nov", hozAlign: "center", bottomCalc: "sum" },
            { title: "October", field: "oct", hozAlign: "center", bottomCalc: "sum" },
            { title: "September", field: "sep", hozAlign: "center", bottomCalc: "sum" },
            { title: "August", field: "aug", hozAlign: "center", bottomCalc: "sum" },
            { title: "July", field: "jul", hozAlign: "center", bottomCalc: "sum" },
            { title: "June", field: "jun", hozAlign: "center", bottomCalc: "sum" },
            { title: "May", field: "may", hozAlign: "center", bottomCalc: "sum" },
            { title: "April", field: "apr", hozAlign: "center", bottomCalc: "sum" },
            { title: "March", field: "mar", hozAlign: "center", bottomCalc: "sum" },
            { title: "February", field: "feb", hozAlign: "center", bottomCalc: "sum" },
            { title: "January", field: "jan", hozAlign: "center", bottomCalc: "sum" },
            { title: "CPA", field: "CPA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CMA", field: "CMA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CPA-AA", field: "CPA_AA", hozAlign: "center", bottomCalc: "sum" },
            { title: "CFA", field: "CFA", hozAlign: "center", bottomCalc: "sum" },
            { title: "FRM", field: "FRM", hozAlign: "center", bottomCalc: "sum" },
            { title: "USP", field: "USP", hozAlign: "center", bottomCalc: "sum" },
            {
              title: "AICPA-Miles",
              field: "AICPA_Miles",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "AICPA-Non-Miles",
              field: "AICPA_Non_Miles",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Total Calls",
              field: "total_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Calls/Day",
              field: "calls_per_day",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Total Unique Connected Calls",
              field: "total_unique_connected_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Connected Calls",
              field: "total_connected_calls",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Utilization",
              field: "utilization",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            { title: "Mandays", field: "mandays", hozAlign: "center", bottomCalc: "sum" },
            {
              title: "Q1 Sales",
              field: "Q1_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q2 Sales",
              field: "Q2_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q3 Sales",
              field: "Q3_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Q4 Sales",
              field: "Q4_sales",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Webinar Count(R | A)",
              field: "webinar_count",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            {
              title: "Visits(T | U | E)",
              field: "visit_count",
              hozAlign: "center",
              bottomCalc: "sum",
            },
            // { title: "Employee Code", field: "e_code", hozAlign: "center"},
            // { title: "Email", field: "email", hozAlign: "center" },
          ];
          if(this.logged_in_spoc_id == 132 || this.logged_in_spoc_id == 122 || this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
            // month_table_headers.push();  
            let old_comment = {title:"Comments", field:"old_comment", width:250, editor:"textarea"}
            previous_year_table_headers.push(old_comment);
            // let new_comment = {title:"Add Comment", field:"new_comment", width:150, editor:"textarea"}
            // previous_year_table_headers.push(new_comment);
            let save_comment = {title:"Add Comment", width:150, formatter: "buttonTick", hozAlign: "center",};
            previous_year_table_headers.push(save_comment);

            let delete_comment = {title:"Delete Comment", formatter: "buttonCross", width:150, hozAlign:"center"};
            previous_year_table_headers.push(delete_comment);
          }
          // let employee_code ={ title: "Employee Code", field: "e_code", hozAlign: "center"};
          previous_year_table_headers.push(employee_code);
          // let email = { title: "Email", field: "email", hozAlign: "center" };
          previous_year_table_headers.push(email);
          if (tableHeader == "year_table") {
            this.year_month_data = [];
            let year = moment().startOf("year").format("YYYY");
            let list_of_months = moment.months(year);
            let current_month = moment().get("month") + 1;
            list_of_months.length = current_month;
            this.year_month = list_of_months.reverse();
            console.log("list_of_months", this.year_month);
            for (let i = 0; i < this.year_month.length; i++) {
              const element = this.year_month[i];
              // console.log("element", element)
              let year_field_name = {
                December: "dec",
                November: "nov",
                October: "oct",
                September: "sep",
                August: "aug",
                July: "jul",
                June: "jun",
                May: "may",
                April: "apr",
                March: "mar",
                February: "feb",
                January: "jan",
              };
              let month_data = {
                title: element,
                field: year_field_name[element],
                hozAlign: "center",
                bottomCalc: "sum",
              };
              this.year_month_data.push(month_data);
            }
            this.year_table_headers = year_table_headers_first.concat(
              this.year_month_data,
              this.year_table_headers_last
            );
          }
          const tableData = {
            month_table: month_table_headers,
            previous_year_table_headers: previous_year_table_headers,
            year_table: this.year_table_headers,
          };
          this.generated_date = response.data.generated_at;
          this.table_data = response.data.data;
          if(this.all_details.length > 0 || this.team_data.length > 0 || this.cc_data.length > 0 || this.level_data.length > 0 ||  this.gm_data.length > 0){
            this.performanceTable(tableData,tableHeader,this.all_details);
          }
          else{
            this.performanceTable(tableData,tableHeader,this.table_data);
          }  
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    performanceTable(tableData,tableHeader,table_data){
      this.show_no_data = false;
      this.tabulator = new Tabulator(this.$refs.table1, {
        maxHeight: "75vh",
        data: table_data,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
        columns: tableData[tableHeader],
        debugInvalidOptions: false,
        movableColumns:true,
        // layout:"fitData",
        initialSort: [{ column: "city_classification", dir: "asc" }],
      });
      this.tabulator.on("cellClick", (e, cell) => this.openAddCommentPopup(e,cell))
      this.tabulator.on("cellClick", (e, cell) => this.openDeleteCommentPopup(e,cell))
      this.$vs.loading.close();
      this.table_show = true;
    },
    refreshData() {
      // this.table_show = false,
      // this.months_tobe_with_year= [],
      // this.generated_date= '',
      // this.city_classification_list= [],
      // this.selected_city_classification= [],
      // this.search_month = "";
      // this.table_data = [];
      this.table_show = false;
      this.show_no_data = true;
      this.selected_month = "";
      this.table_data = [];
      // this.year_table_headers = [];
      // this.previous_year_table_headers = [],
      // this.month_table_headers = []
    },
    getMasterPerformanceComments(){
      let url = `${constants.MILES_CM_BACK}getMasterPerformanceComments`;
         axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("getMasterPerformanceComments", response);
            this.comments_data = response.data;
            console.log("this.comments_data ", this.comments_data );
            
            if(this.cell != ""){
              let row = this.cell.getRow();
              let comment = "";
                
                this.data.forEach(element => {
                  if(this.cell._cell.row.data.user_id == element.user_id){
                    element.old_comment = "";
                    this.comments_data.forEach(comment => {
                      if(element.user_id == comment.user_id){
                        if(element.hasOwnProperty('old_comment')){
                          let new_comment = moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment;
                          let new_string =  new_comment + "\n" + element.old_comment;
                          Object.assign(element, {old_comment: new_string})
                        }  
                        if(!element.hasOwnProperty('old_comment')){
                          console.log("date",moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment);
                          let new_comment = moment(comment.created_at).format("DD-MMM-YYYY") + ":" + " " + comment.comment;
                          Object.assign(element, {old_comment: new_comment})
                        }
                        console.log("show");
                      }  
                    });
                    comment = element.old_comment;
                  }
                });
                row.update({
                  "new_comment": ""
                });
                row.update({
                  "old_comment": comment
                });
            }
            // this.getData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addMasterPerformanceComments(){

      let user_id = this.cell._cell.row.data.user_id;
      let obj = {
        user_id: user_id,
        comment: this.new_comment,
      }

      console.log(obj);
        let url = `${constants.MILES_CM_BACK}addMasterPerformanceComments`;
        axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("addMasterPerformanceComments", response);
          if(response.data == 'success'){
            // this.refreshData();

            this.$vs.notify({
              text: "Comment Added Successfully",
              color: "success",
            });
            this.open_add_comment_popup = false;
            this.getMasterPerformanceComments();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    openDeleteCommentPopup(e,cell){
      console.log("delete Comment data",cell._cell.row.data.old_comment );
      if(cell._cell.row.data.old_comment != '' && cell._cell.row.data.old_comment != undefined ){
        let column_name = cell.getColumn()._column.definition.title;
        if(column_name == "Delete Comment"){
          this.delete_comment_object.user_id = cell._cell.row.data.user_id;
          this.delete_comments = [];
          for (let i = 0; i < this.comments_data.length; i++) {
            const element = this.comments_data[i];
            if(element.user_id == this.delete_comment_object.user_id){
              let new_comment = moment(element.created_at).format("DD-MMM-YYYY") + ":" + " " + element.comment;
              Object.assign(element, {comment_with_time: new_comment})
              this.delete_comments.push(element);
            }
          }
          if(this.delete_comments.length != 0){
            this.open_delete_popup = true;
          }
        }
      }
    },
    openAddCommentPopup(e,cell){
      this.cell = cell;
      console.log("Add Comment data",cell.getColumn()._column.definition.title );
      let column_name = this.cell.getColumn()._column.definition.title; 
      if(column_name == "Add Comment"){
        this.new_comment = "";
        this.open_add_comment_popup = true;
      } 
    },
    deleteComment(text, id){
      console.log("comments",id);
      let obj = {};
      if(text == 'delete all comments'){
        obj.user_id = id;
        obj.comment_id = "";
      }
      if(text == 'delete single comment'){
        obj.comment_id = id;
        obj.user_id = "";
      }
      let url = `${constants.MILES_CM_BACK}deleteMasterPerformanceComments`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("deleteMasterPerformanceComments", response);
          if(response.data == "Success"){

              this.$vs.notify({
                text: "Deleted Successfully",
                color: "success",
              });
            this.open_delete_popup = false;
            this.getMasterPerformanceComments();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    downloadCvsTable() {
      this.tabulator.download("csv", `Master Performance ${this.selected_month}.csv`, {
        bom: true,
      });
    },
  },
};
</script>
<style>
.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
</style>
